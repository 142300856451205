import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { UserRootRoutingModule } from './user-root.routing'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { PipesModule } from './../_pipes/pipes.module'
import { DirectivesModule } from './../_directives/directives.module'

// Components
import { LoginComponent } from './login/login.component'
import { RegisterComponent } from './register/register.component'
import { UserComponent } from './user-root/user/user.component'
import { UserHeaderComponent } from './user-root/user-header/user-header.component'
import { AccountComponent } from './user-root/account/account.component'
import { ReferComponent } from './user-root/refer/refer.component'
import { RecruitDetailsComponent } from './user-root/recruit-details/recruit-details.component'

//Services
import { AuthGuard } from './../_guard/auth.guard';
import { PendingBookingsComponent } from './user-root/pending-bookings/pending-bookings.component';
import { UserRefferalsComponent } from './user-root/user-refferals/user-refferals.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component'


@NgModule({
	declarations: [
		UserComponent, LoginComponent, RegisterComponent,
		UserHeaderComponent, AccountComponent, ReferComponent, RecruitDetailsComponent, PendingBookingsComponent, UserRefferalsComponent, PrivacyPolicyComponent, TermsOfUseComponent,
	],
	imports: [
		CommonModule,
		UserRootRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		PipesModule,
		DirectivesModule
	],
	providers: [
		AuthGuard
	]
})
export class UserRootModule { }
