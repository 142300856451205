<div class="container">
	<h1>Divine Limousine Privacy Policy</h1>

	<p>Last Updated: April 11, 2023</p>

	<div class="section">
		<h3 class="section-title">1. Introduction.</h3>

		<p>Divine Limousine is an organization working to provide a divine experience to people looking for daily transportation, a once in a lifetime event, tours to sites they have never seen, a night on the town, or the opportunity to spend that magical moment with your significant other or friends.</p>
		<p>This Privacy Policy describes the privacy practices for information collected by Divine Limousine on any website where the policy has been posted by Divine Limousine. Please read it carefully. By using our website or services, you accept this Privacy Policy.</p>
		<p>If you have any questions, please contact us using the information in the “Contact Us” section at the end of this policy.</p>
	</div>

	<div class="section">
		<h3 class="section-title">2. We collect various types of personal data depending on your interactions with us.</h3>
		<p>Divine Limousine collects information using:</p>
		<ol>
			<li>Your professional networking, customer relationship management, or social media platforms if you choose to link them;</li>
			<li>Email, email accounts, and contacts, if you choose to link them;</li>
			<li>Our website; or</li>
			<li>With your consent.</li>
		</ol>

		<p>When you link your email accounts, professional networking, customer relationship management, or social media platforms, we may obtain information about your contacts, or related information. We may also collect information about your use of our products or services. We also collect information about you or your contacts from our service providers, partners, or other sources of publicly available information.</p>
		<p>The personal data that Divine Limousine may collect includes:</p>
		<ol>
			<li>Biographic information, such as names;</li>
			<li>Contact information, such as addresses, telephone numbers, email addresses, and professional networking and social media platform contact information;</li>
			<li>Professional information, such as title, industry, professional and employment background, and profile;</li>
			<li>Preferences and interests, such as contact preferences and preferred language;</li>
			<li>Credentials, such as usernames and passwords for Divine Limousine website accounts;</li>
			<li>Financial data, such as credit and debit card or bank account information relating to your purchases from Divine Limousine; and</li>
			<li>Other information you may provide to us.</li>
		</ol>

		<p>We may also collect other identifying information we may obtain from you, as disclosed in other policies or notices.</p>
		<p>You may choose not to provide us with your personal data. If you choose not to provide us with your personal data, we may be unable to provide you with all functional aspects of our products and services.</p>
	</div>

	<div class="section">
		<h3 class="section-title">3. We use your personal data to provide our services and for other purposes.</h3>

		<h4>A. Providing Products and Services.</h4>

		<p>Divine Limousine may use your personal data for our legitimate business interests and to provide you or our other customers with our products and services.</p>
		<p>We may use your contact information to communicate with you about your order of our products or services, including to process payments or send receipts. Divine Limousine may also use your personal data to add new features or capabilities to our products or services or otherwise improve them. We may use your personal data to administer your website account with us. We may also use your personal data to produce aggregate or statistical data about our customers, users, products, services, or industry.</p>
		<p>Our legal bases for processing your personal data to provide you with our products and services are the performance of contracts and the furtherance of Divine Limousine’s legitimate business interests. Our legitimate business interests may relate to the provision of our services, fraud detection and prevention, risk assessment, or improvement of our products and services. If you choose not to provide your personal data where it is required as part of a contract for our products or services or to create your website account, we may be unable to provide you with our products or services.</p>
		<p>To buy products and services from Divine Limousine, we may need your biographic information and financial information. If you purchase a product or service from Divine Limousine, we may also collect and use additional information from your purchase transaction, such as the cost of the transaction, type of service or product purchased, payment type, currency, and location of payment.</p>

		<h4>B. Communications and Customer Service.</h4>
		<p>Divine Limousine may use your personal data to communicate with you and provide you with customer service. For example, we may request personal data from you regarding surveys or contests. We may use survey information for monitoring or improving this website, our services, or products. Additionally, we may use your personal data to respond to inquiries, provide support, address issues or disputes, or otherwise manage our relationship with you. We may also retain and use records regarding the content and nature of our communication with you, whether by email, telephone call, internet chat or otherwise. We may merge personal data with other information Divine Limousine has about you.</p>
		<p>Participation in our surveys or contests is voluntary and you may choose to participate and disclose this personal data. We may use your contact information (such as name and billing address) and demographic information (such as zip code or age) for these surveys or contests. We may use this information to notify the winners and award prizes.</p>
		<p>Our legal bases for processing your personal data for communications and customer service purposes are your consent and furthering Divine Limousine’s legitimate business interests, which include continuing and improving customer relations and updating customer details and preferences.</p>
		
		<h4>C. Marketing.</h4>
		<p>We may use your personal data so that we, or our affiliates, can market, advertise, or otherwise promote products and services that may be of interest to you based on your profile. We may advertise to you on websites or using email or professional networking or social media platforms. Unless you ask us not to, Divine Limousine may contact you in the future to tell you about new products or services. We may use your personal data for website analytics and customer relationship management purposes.</p>
		<p>Our legal basis for processing your personal data for marketing purposes is Divine Limousine’s legitimate business interests relating to marketing, advertising, and promoting our products and services.</p>
		
		<h4>D. Legal and Compliance.</h4>
		<p>Divine Limousine may also use your personal data in connection with our legal or compliance obligations. These obligations may include cooperating or complying with government or law enforcement investigations, litigation, discovery, regulatory requirements, corporate investigations, dispute resolution, collections, national security, public interest, or others.</p>
		<p>Our legal bases for processing your personal data for legal and compliance purposes are to meet legal obligations and legitimate business interests relating to compliance with other local laws that we may be subject to.</p>
	</div>

	<div class="section">
		<h3 class="section-title">4. We share your personal data to provide our services and consistent with this privacy policy.</h3>
		<p>Divine Limousine may share your personal data for the purposes of providing our products or services to our customers, communications and customer service, marketing, and as a part of our legal and compliance obligations.</p>
		<p>When Divine Limousine shares your personal data, we do so consistent with this Privacy Policy or to the extent necessary to carry out the purposes described in this policy, and as required or permitted by applicable law.</p>
		<p>Our service providers may include information technology providers, communications technology providers, email or marketing services providers, contact information verification services providers, consultants, auditors, attorneys, outsourcing services service providers, financial services providers, payment processing and billing service providers, customer service providers, or others.</p>
		<p>In providing you with communications and customer service and marketing, we may share your personal data with customer relationship management service providers, advertising and marketing service providers, social media platforms (where you use those services to connect with us), and others.</p>
		<p>We may also share your personal data to comply with our legal and compliance obligations; enforce our agreements; detect, prevent, or address fraud or other suspected illegal activity; and protect our rights and the rights of others. As a part of legal and compliance efforts, we may share personal data with our attorneys, governmental entities, supervisory authorities, law enforcement agencies, or others as required or permitted by applicable law. Divine Limousine may share personal data as part of corporate transactions, such as mergers, acquisitions, or divestitures; however, your personal data remains subject to promises made in this Privacy Policy during those transactions.</p>
		<p>Divine Limousine may also share your personal data with its employees, contractors, service providers, and others.</p>
	</div>

	<div class="section">
		<h3 class="section-title">5. We use your personal data for employment applications.</h3>
		<p>If you are applying for a job at Divine Limousine, we may collect information from you, including application information, resumes, and employment records; information received from references; work eligibility and equal opportunity employment information (as required or permitted by applicable law); or other information.</p>
		<p>We may use your personal data to consider your employment application. We may share your personal data relating to your application with our employees, affiliates, subsidiaries, and service providers, including recruiting service providers, hiring consultants and agencies, information and technology service providers, human resources information system providers, and others. Please also review additional terms that may apply when you submit your application.</p>
	</div>

	<div class="section">
		<h3 class="section-title">6. We use cookies and similar technologies.</h3>
		<p>We may use "cookies", web beacons, or similar technologies on our website. A cookie is a piece of data stored on a website visitor's device to help us improve access, use, and security of our website and identify repeat visitors to our website. For instance, when we use a cookie to identify you, you would not have to enter a password more than once, thereby saving time while on our website. Web beacons are objects embedded into webpages that allow us to monitor site activity. Cookies, web beacons, and similar technologies can also help us track and target your interests so that we can enhance your experience on our website, conduct analytics, determine the effectiveness of promotional campaigns, and direct advertisements or other marketing activities.</p>
		<p>We may also collect other automated information from you, such as your browser type and language, your operating system, your Internet Protocol address, the date and time of your visit, the duration of your visit, the URLs of websites you visited before and after visiting our website, the web search that landed you on our website, and web pages and advertisements you view and links you click on within our website.</p>
		<p>Some of our business partners, such as advertisers or our merchandise vendors, may also use cookies, web beacons, or similar technologies on our website. We may use cookies and similar technologies.  These cookies and similar technologies may collect personal data about you over time and across different websites when you use our website. You may opt out of such collection by using the hyperlinks included above. Divine Limousine website does not currently respond to browser Do-Not-Track signals.</p>
		<p>You may also accept or reject those cookies or block those technologies. Your browser’s help section should explain how to configure your browser’s cookie handling. Your browser may also have add-ons that may help you manage web beacons and similar technologies. Please note that some portions of our website (such as the customer portal) require the use of cookies to function properly.</p>
		<p>We may also include integrations or links to social media platforms such as Facebook, Twitter, Google, Instagram, and others. These features for the social media platforms may also use cookies or similar technologies. They may collect your information, such as your IP address, device and browser type, and the web page you visited. You may also wish to review the privacy policies for those social media platforms to learn how they treat your information. We may collect information about you from those platforms when you access our website using those platforms.</p>
		<p>Our website may use Google Analytics and Google Tag Manager, which are web analytics services provided by Google LLC. These services use cookies to analyze your use of our website. The information collected by these cookies about your use of our website is transmitted to and stored on Google servers. Google uses this information to assess your use of our website and provides reports on data. This data may include how often you visit our website, what pages you view when you visit the website, and what other websites you have visited prior to visiting our website. Google may transfer this information to third parties, if required by law or if third parties process the information on behalf of Google.</p>
		<p>You may review Google’s data privacy practices for Google Analytics and data privacy practices for Google Tag Manager. You may also review Google’s Privacy Policy and Terms of Service for more information. To opt out of being tracked by Google Analytics, you may download and install the Google Analytics Opt-out Browser Add-on.</p>
	</div>

	<div class="section">
		<h3 class="section-title">7. We do not knowingly collect sensitive personal data nor children’s data.</h3>
		<p>Divine Limousine does not knowingly collect or use sensitive personal data (such as data that reveal racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership). Divine Limousine does not knowingly collect or use personal data from children under the age of 13.</p>
	</div>

	<div class="section">
		<h3 class="section-title">8. We provide links to other websites.</h3>
		<p>This website contains links to other websites, and we are not responsible for the content or privacy practices of such other websites. Please be aware when you leave our website, and read the privacy policy of any other website that collects your information.</p>
	</div>

	<div class="section">
		<h3 class="section-title">9. You may have a right to access, correct, or delete your personal data.</h3>
		<p>Depending on circumstances and applicable law, you may have the right to access, correct, object, or delete your personal data. You may access the app to correct or update your personal data. You may also contact us using the information in the “Contact Us” section at the end of this Privacy Policy. We may need to verify your identity and the applicability of the rights under the law to you before we grant your right to object, access, correct, or delete. Once we do, we will provide you with a copy of the personal data that you request access to as soon as practically possible. As permitted by applicable law, we may limit these rights. For example, we may limit them where they adversely affect other individuals’ personal data, trade secrets, or intellectual property, among other reasons. We will tell you the reasons for any denial of your request.</p>
		<p>Depending on the circumstances and applicable law, you may also have the right to lodge a complaint with a local supervisory authority; the right to have Divine Limousine restrict the processing of your personal data; the right of portability of your personal data; or the right to withdraw any consent relating to the processing of your personal data. Your withdrawal of consent, if any, does not affect the lawfulness of processing based on consent before its withdrawal.</p>
		<p>Unless otherwise required by applicable law, Divine Limousine does not remove company information or summaries from its directory.</p>
		<p>You may opt out of any future emails from us at any time by using the unsubscribe links included in our email communications to you. You may also opt out of other communications from us by contacting us using the information in the “Contact Us” section below.</p>
		<p>You may revoke access to your email, professional networking, customer relationship management, or social media platforms using the settings relating to those email service providers or platforms. Please refer to the help section of those email service providers or platforms for additional help.</p>
	</div>

	<div class="section">
		<h3 class="section-title">10. We take precautions for the security and integrity of personal data.</h3>
		<p>Divine Limousine takes some precautions to try to protect your personal data both online and offline and to try to keep personal data accurate, current, complete, and reliable for its intended use. We use various security technologies and procedures to help protect your personal data from loss, misuse, unauthorized access, disclosure, alteration, and destruction. When collecting or transmitting confidential information, such as credit card numbers, Divine Limousine protects that information through encryption.</p>
		<p>Divine Limousine retains personal data for as long as reasonably required to comply with our legal obligations and in compliance with our data retention policies and procedures. We also retain your personal data for as long as reasonably required for our business purposes, including to provide our services to our customers, to resolve disputes, to enforce our agreements, and for the safety, security, and reliability of our products and services.</p>
	</div>

	<div class="section">
		<h3 class="section-title">11. We may have adequate protections for international transfers.</h3>
		<p>Divine Limousine is the data controller for the personal data collected on this website and through other means. Divine Limousine may store, access, or use your personal data in the United States or in any other country where Divine Limousine or its service providers do business or maintain facilities. Therefore, we may transfer personal data between different jurisdictions.</p>
		<p>Where required to by its customers, Divine Limousine transfers personal data in compliance with the General Data Protection Regulation and other applicable law. For example, Divine Limousine may use standard contractual clauses issued by the European Commission for data transfers to data controllers and data processors established outside of the European Union, European Economic Area, Switzerland, and the UK.</p>
	</div>

	<div class="section">
		<h3 class="section-title">12. We may update this privacy policy.</h3>
		<p>Divine Limousine may update this Privacy Policy from time to time as permitted by law. When we update these terms, we will post them on this page and change the “last updated” date on the policy. If we make material changes to our Privacy Policy, we will notify you either by a prominent notice on our website before the changes take effect or by emailing you about those changes. Where required by applicable law, we will revise this Privacy Policy if we change the purposes of the processing of your personal data.</p>
		<p>Please review our website and policies frequently to learn how Divine Limousine uses your personal data. Your continued use of our website after our notification means that you have accepted our updated Privacy Policy.</p>
	</div>

	<div class="section">
		<h3 class="section-title">13. You may contact us.</h3>
		<p>If you have any questions or concerns about this Privacy Policy or any of your rights under it, you may contact us at:</p>
		
		<p>
			Divine Limousine LLC.<br>
			Attention: Privacy Officer<br>
			P.O. Box 651676<br>
			Salt Lake City, UT  84165
		</p>
		<p>or by email at <a href="mailto:divine.info@divinelimoutah.com">divine.info@divinelimoutah.com</a></p>
	</div>
</div>