<div class="container">
	<h1>Divine Limousine Terms of Use</h1>
	<p>Last Updated: April 11, 2023</p>

	<div class="section">
		<p>THESE TERMS OF USE (“TERMS”) GOVERN YOUR USE OF ALL WEBSITES, APPLICATIONS, TOOLS AND DIGITAL PRODUCTS AND SERVICES MADE AVAILABLE TO YOU BY DIVINE LIMOUSINE (“DIVINE LIMOUSINE,” “US,” “WE,” OR “OUR”), INCLUDING ANY FUNCTIONALITY, CONTENT, DATA OR SERVICE INCLUDED THEREIN AND MADE AVAILABLE TO YOU BY DIVINE LIMOUSINE (COLLECTIVELY, THE “SERVICES”). PLEASE REVIEW THESE TERMS CAREFULLY. BY ACCESSING OR USING ANY PART OF THE SERVICES OR OTHERWISE ACCEPTING THESE TERMS, YOU AGREE THAT THESE TERMS SHALL APPLY AND BECOME BINDING ON YOU AND THE COMPANY THAT EMPLOYS YOU OR THAT YOU REPRESENT (COLLECTIVELY, “YOU”) ON THE FIRST DATE YOU ACCESS OR USE ANY PART OF THE SERVICES OR OTHERWISE ACCEPT THESE TERMS, WHETHER BY EXECUTING AN ORDER FORM REFERENCING THIS AGREEMENT, CLICKING A BOX INDICATING ACCEPTANCE OF THIS AGREEMENT OR OTHERWISE USING THE SERVICES. IF YOU DO NOT AGREE, YOU ARE NOT AUTHORIZED TO USE THE SERVICES.</p>
		<p>IF YOU ARE AN INDIVIDUAL ACCEPTING THIS AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY AND ITS AFFILIATES TO THIS AGREEMENT, IN WHICH CASE THE TERM “YOU” SHALL REFER TO SUCH ENTITY AND ITS AFFILIATES. IF YOU DO NOT HAVE SUCH AUTHORITY, YOU MUST NOT ACCEPT THIS AGREEMENT AND MAY NOT USE THE SERVICES.</p>
		<p>NOTICE OF BINDING ARBITRATION AND WAIVER OF CLASS ACTION: THESE TERMS INCLUDE A MANDATORY BINDING ARBITRATION PROVISION AND CLASS ACTION WAIVER, WHICH LIMIT YOUR RIGHTS IN THE EVENT OF A DISPUTE RELATED TO THE SERVICES. PLEASE REFER TO THE “ARBITRATION AGREEMENT AND CLASS ACTION WAIVER” SECTION BELOW FOR MORE INFORMATION.</p>
		<p>DIVINE LIMOUSINE'S COMPETITORS ARE PROHIBITED FROM ACCESSING OR USING THE SERVICES IN ANY WAY, EXCEPT WITH DIVINE LIMOUSINE'S PRIOR WRITTEN CONSENT.</p>
	</div>
	
	<div class="section">
		<h3 class="section-title">1. Changes to the Terms and Services</h3>

		<p>We may change these Terms from time to time by posting a revised version of the Terms on our website. Changes to the Terms are effective immediately upon posting them on our website. We will indicate the date on which the Terms were last updated at the top of these Terms. Your continued use of the Services after such change constitutes your agreement to the modified Terms. You should review the Services frequently for revisions to the Terms. If you do not agree to the modified Terms, you should discontinue your use of the Services. In addition, Divine Limousine may at any time modify any part of the Services, including any functionality or feature offered through the Services, temporarily or permanently, for any reason, with or without notice to you.</p>
	</div>

	<div class="section">
		<h3 class="section-title">2. Privacy Policy</h3>

		<p>Our <a routerLink="/privacy">Privacy Policy</a> applies to personal information collected from you through the Services.</p>
	</div>

	<div class="section">
		<h3 class="section-title">3. Minimum Age</h3>

		<p>You represent that you are at least 18 years of age (or the age of majority in the State in which you reside). The Services are not intended for minors and you may not use the Services if you are under 18 years of age (or the age of majority in the State in which you reside).</p>
	</div>

	<div class="section">
		<h3 class="section-title">4. Divine Limousine Data</h3>

		<p>Divine Limousine makes available certain data and information to users or the public through the Services, including, but not limited to, information about professional contacts and businesses (collectively, “Divine Limousine Data”). As between you and Divine Limousine, all Divine Limousine Data is the property of Divine Limousine. Divine Limousine retains all right, title and interest in Divine Limousine Data.</p>
		<p>You agree to access, use, disclose, retain and otherwise process Divine Limousine Data only for the purpose of supporting business-to-business (“B2B”) development opportunities, including for identifying and researching prospective or existing B2B sales, marketing or recruiting opportunities (collectively, the “Permitted Purposes”) and only as otherwise permitted by these Terms. Unless required by applicable law, you agree not to access, use, disclose, retain or otherwise process Divine Limousine Data for any other purpose. You are strictly forbidden from accessing, using or otherwise processing Divine Limousine Data:

		<ol>
			<li>in connection with any non-B2B customers or prospects;</li>
			<li>in any unlawful, biased, fraudulent or discriminatory manner or for purposes of tracking, profiling or surveilling individuals;</li>
			<li>to send fraudulent or unlawful communications to individuals or entities; or</li>
			<li>to sell, rent, license, release, disclose, distribute, disseminate, sublicense, transfer, or otherwise make available, in whole or in part, any Divine Limousine Data to a third party for monetary or other valuable consideration, or otherwise incorporate any portion of Divine Limousine Data into your own or others’ products or services.</li>
		</ol>

		<p>You acknowledge and agree that Divine Limousine is not a consumer reporting agency, and you will not use any Divine Limousine Data or the Services for any purpose regulated under the Fair Credit Reporting Act (“FCRA”), 15 U.S.C. § 1681b, including, without limitation, as a factor in (i) establishing an individual’s eligibility for credit or insurance or assessing risks associated with existing credit obligations, (ii) evaluating an individual for employment purposes, (iii) determining an individual’s eligibility for a license or other benefit that depends on an applicant’s financial responsibility or status, or (iv) for any other purposes subject to the FCRA.You shall take reasonable steps to protect Divine Limousine Data from misuse, theft, unauthorized access, misappropriation or other compromise. You shall immediately notify Divine Limousine in writing at <a href="mailto:divine.info@divinelimousine.com">divine.info@divinelimoutah.com</a> upon becoming aware of any actual or suspected unauthorized access to or misuse, theft, misappropriation or other compromise of Divine Limousine Data (“Data Incident”). In the event of a Data Incident, you agree to promptly investigate the incident, take all necessary and advisable corrective actions, and cooperate fully with Divine Limousine in all reasonable and lawful efforts to prevent, mitigate or rectify the incident. You will provide Divine Limousine with such assurances as Divine Limousine may request that such Data Incident is not likely to reoccur.
	</div>

	<div class="section">
		<h3 class="section-title">5. Prohibited Uses</h3>

		<p>You agree to use the Services professionally, ethically and lawfully. You may not use the Services in any manner that:</p>
		<ol>
			<li>Is defamatory, unlawful, harassing, abusive, threatening, obscene, hateful, sexist, or racially or ethnically offensive;</li>
			<li>Constitutes a breach of any person’s privacy or publicity rights;</li>
			<li>Violates any statute, regulation, or ordinance;</li>
			<li>Promotes software or services that deliver unsolicited mail; or</li>
			<li>Promotes, encourages, or facilitates terrorism or other activities that risk national security.</li>
			<li>You may not upload, post, communicate, or otherwise transmit any information or other materials on or through the Services that:</li>
			<li>Contain any viruses, Trojan horses, worms, cancel bots, time bombs, spyware, or similar computer malicious code;</li>
			<li>Is knowingly untrue, inaccurate, or misleading;</li>
			<li>Violates U.S. law, or is obscene, obscene as to minors, child pornography, defamatory, racist, lewd, lascivious, filthy, excessively violent, harassing, or otherwise objectionable; or</li>
			<li>Damages or interferes with the operations of the Services or with other users of the Services.</li>
		</ol>

		<p>You further agree that you will not:</p>
		<ol>
			<li>Access the Services or obtain information therefrom in an unauthorized manner;</li>
			<li>Misuse passwords, Divine Limousine Data, the Services, third-party services, or our systems or network;</li>
			<li>Share, sell, distribute or otherwise transfer your Account or allow your login credentials to be used by any other individual.</li>
			<li>Impersonate any person or entity;</li>
			<li>Falsely state or otherwise misrepresent your affiliation with a person or entity;</li>
			<li>Use Divine Limousine Data or content from the Services for any purpose inconsistent with the purpose of the Services, any purpose other than the Permitted Purposes or in violation of these Terms;</li>
			<li>Use hidden pages, images, or restricted access pages;</li>
			<li>Use the Services in connection with surveys, contests, pyramid schemes, chain letters, junk email, spam, or any duplicative or unsolicited messages;</li>
			<li>Use any automated procedure to gather information or data from the Services by means of what is commonly called a “bot” or otherwise;</li>
			<li>Interfere with or disrupt the Services, servers, or networks connected to the Services;</li>
			<li>Integrate Divine Limousine Data into any customer relationship management, marketing automation, or sales enablement platform to allow unauthorized users to access or use Divine Limousine Data;</li>
			<li>Breach or attempt to breach the security of software, networks, servers, data, computers, or other hardware relating to the Services (or that of any third party that is hosting or interfacing with any part of the Services);</li>
			<li>Create any frames at any other websites pertaining to or using any of the information provided through the Services, or promote bonus items, giveaways, random drawings, contests, or prizes on the Services;</li>
			<li>Duplicate, copy, or otherwise exploit the Services or content therefrom for unauthorized commercial purposes; or</li>
			<li>Violate the rights of third parties.</li>
		</ol>
	</div>

	<div class="section">
		<h3 class="section-title">6. Submitted Data</h3>

		<p>In using the Services, Divine Limousine may collect data from you and your device through various sources, such as when you use our websites, Google Chrome Extension or features we offer to integrate our Services with your accounts and data on third-party platforms and services (collectively, “Submitted Data”). If you enable our integration features in the Services, you authorize us to collect Submitted Data from your professional networking and customer relationship management platforms, email accounts, social media accounts and other online accounts that you choose to integrate into our Services. We may use the Submitted Data to provide our Services or in accordance with these Terms or other documentation made available to you. You acknowledge and understand that upon being enabled, these integrations will stay in place and may continue to collect Submitted Data until you remove them.</p>
		
		<p>You shall not make available to Divine Limousine any Submitted Data that you are prohibited from sharing or disclosing to Divine Limousine. You represent and warrant that you or your licensors have the right to share all Submitted Data with Divine Limousine in accordance with these Terms, and that the Submitted Data does not violate or infringe upon our or any third party’s intellectual property rights (including copyrights, trademarks, trade secrets, patents, and publicity rights). You also represent and warrant that the Submitted Data is accurate and complete to the best of your knowledge. You agree that you are solely liable for any losses you or we may incur as a result of the Submitted Data for reasons of infringement or otherwise.</p>
		
		<p>You should not make available any Submitted Data that you wish to remain strictly confidential. You hereby acknowledge that you understand and agree that Divine Limousine will make available any contact-related information contained in your Submitted Data to our users or the public, including without limitation, any name, email address, phone number, professional information or other personal information related to your contacts described in our Privacy Policy. You agree that by using the Services, you are granting Divine Limousine an irrevocable, perpetual, non-exclusive, transferrable, sublicensable, royalty free, worldwide license to use, modify, reproduce, adapt, sell, distribute, perform, publish, create derivative works based on, publicly display, and otherwise exploit all or any portion of Submitted Data for any purpose whatsoever, without restriction, compensation to you or attributing the Submitted Data to you.</p>
		
		<p>You are responsible for reviewing Submitted Data for accuracy, completeness and compliance with these Terms. We may remove, disable, or restrict access to or the availability of any Submitted Data from the Services that we believe, in our discretion, to violate these Terms (whether or not we are, in fact, correct in our assessment). If you believe that we have acted mistakenly with respect to certain Submitted Data, you may contact us, in which case we may investigate the matter further. We nevertheless may take no further action.</p>
	</div>

	<div class="section">
		<h3 class="section-title">7. Intellectual Property</h3>

		<p>All content made available to you by Divine Limousine in connection with the Services, including, without limitation, Divine Limousine Data, software, text, graphics and images, trademarks, service marks, logos and any other intellectual property, but excluding your Submitted Data (collectively “Divine Limousine Content”) is the property of Divine Limousine and its licensors. We grant you a personal, non-sublicensable, non-transferable, non-exclusive, and revocable license to use the Services solely for the Permitted Purposes in accordance with these Terms. We may revoke this license at any time for any or no reason. You may not sell or publicly display or distribute any Divine Limousine Content or otherwise use the Divine Limousine Content in a manner not expressly permitted in these Terms without Divine Limousine’s prior written consent.</p>
		<p>Without limitation of the foregoing, the Services may contain certain content from third parties which may be subject to its own additional license terms and policies (“Third Party Content”). You should refer to the terms and policies posted on third party websites prior to using any such Services. Divine Limousine does not endorse, oppose or edit any opinion, claim, or analysis expressed by any third party. You agree that Divine Limousine is not responsible for the substance or accessibility of any Third Party Content accessible through the Services and understand that you bear all risks associated with your access to or use of such content.</p>
		<p>The Services contains our intellectual property and that of other third parties and is protected by U.S. and international copyright law and other intellectual property laws. Unauthorized reproduction or distribution of the Services or Divine Limousine Content, in whole or in part, without our express written consent, may result in civil and criminal penalties, and may be prosecuted to the maximum extent possible under law. The publication or dissemination of any Divine Limousine Content or the Services, without our express, written consent is prohibited and may result in termination of your rights to use or access the Services. Unless otherwise explicitly stated in these Terms, we do not provide you with any right to our intellectual property rights, or those of a third party, whether by estoppel, implication, waiver or otherwise, and all rights are hereby reserved by Divine Limousine.</p>
		<p>You agree not to modify, alter, remove, or deface any of the trademarks, service marks or other intellectual property made available by us in connection with the Services. You agree not to use any Divine Limousine Content for any purpose other than the Permitted Purposes in accordance with these Terms. You agree not to defame or disparage us, our trademarks or service marks, or any aspect of the Services. Unless otherwise specifically agreed in writing by Divine Limousine, you agree not to adapt, translate, modify, decompile, disassemble, copy, create derivative works of, or reverse engineer the Services or any software or programs used in connection with the Services.</p>
	</div>

	<div class="section">
		<h3 class="section-title">8. Copyright Infringement Notification & Digital Millennium Copyright Act</h3>

		<p>We endeavor to comply with the Digital Millennium Copyright Act of 1998. If you believe that the Services contain content that infringes on your copyright, please forward the following information to <a href="mailto:divine.info@divinelimousine.com">divine.info@divinelimoutah.com</a>.
		<ol>
			<li>Your postal address, telephone number, and email address;</li>
			<li>A description of the copyrighted work that you claim has been infringed;</li>
			<li>A description of the location of an original or an authorized copy of the copyrighted work, such as a URL;</li>
			<li>A statement by you that you have a good faith belief that the disputed use is not authorized by you, the copyright owner, its agent, or the law;</li>
			<li>An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest; and</li>
			<li>A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.</li>
		</ol>

		<p>It is our policy to terminate the access rights of repeat infringers.</p>
	</div>

	<div class="section">
		<h3 class="section-title">9. Compliance</h3>

		<p>You agree to use the Services, including without limitation Divine Limousine Data, in compliance with these Terms and all applicable laws, rules and regulations, including without limitation, privacy laws, rules and regulations. To the extent required by applicable law, you will maintain a publicly available and easily accessible privacy policy that (i) complies with applicable privacy laws and regulations, and (ii) comprehensively, clearly and accurately describes your collection, use, sharing, protection and other processing of personal information in connection with your use of the Services. You will ensure the content of your privacy policy remains consistent with these Terms and our Privacy Policy.</p>

		<p>You are solely responsible for any communications between you and any individual whose contact or business information is made available through the Services. You shall communicate with such individuals in compliance with applicable laws, rules and regulations. Without limitation of the foregoing, to the extent required by applicable law, you shall (i) allow individuals to opt out of receiving marketing emails, text messages or other communications from you, (ii) maintain exclusionary lists relating to individuals who wish not to receive marketing communications from you, and (iii) comply with applicable do-not-call and do-not-email lists.</p>

		<p>In the event a law, regulation, enforcement action, investigation, litigation or claim, or any other circumstance, is reasonably likely to adversely affect your ability to comply with these Terms, you agree to promptly notify Divine Limousine and take reasonable and appropriate steps to prevent or remedy any non-compliance, or promptly cease your use of the Service and processing of any portion of Divine Limousine Data. Divine Limousine may, at any time and without prior notice to you or penalty of any kind to Divine Limousine, suspend, restrict, revoke or terminate your access to or use of any or all of the Service if Divine Limousine, in its sole discretion, believes you have violated these Terms or as Divine Limousine otherwise deems appropriate to protect the security or integrity of the Services.</p>

		<p>You acknowledge and agree that Divine Limousine will have the right to monitor your use of the Services and your compliance with these Terms. You must not interfere or attempt to interfere with such monitoring. Upon request from Divine Limousine, you agree to make available to Divine Limousine all information and materials reasonably necessary to demonstrate compliance with these Terms, and allow for and contribute to audits and inspections conducted by or on behalf of Divine Limousine relating in any way to your compliance. You shall reasonably cooperate with any such request, monitoring or inspection initiated by Divine Limousine. Divine Limousine may ask you to validate or certify your continued compliance with these Terms. Failure to validate or self-certify your compliance upon request from Divine Limousine may result in Divine Limousine revoking your access to the Services, or requiring you to promptly delete Divine Limousine Data in your possession, custody or control.</p>

		<p>We may request that you delete the personal information of individuals who have requested that their personal information be deleted. Upon request from Divine Limousine, you agree to take all reasonable and appropriate steps to promptly and securely delete the relevant Divine Limousine Data in your possession, custody or control.</p>
	</div>

	<div class="section">
		<h3 class="section-title">10. Third-Party Links and Features</h3>

		<p>We may provide third-party links or features through our Services as a convenience to you. We have no control over the content made available on these third-party websites or through these features and make no representations about their availability, content, or security. Third-party links and features are not intended to imply sponsorship, affiliation, responsibility, or endorsement. We provide no warranty as to the accuracy, availability, completeness or legality of any information made available on these third-party sites or from these features. We provide no warranty that these third-party sites and features are virus free and will not otherwise harm your device, computer, network, or system. We are not liable for those third parties’ actions or their failure to act. These third parties may have their own privacy policies and terms of use for their websites and services, and you may be bound by them. You agree to be responsible to review those documents. If you decide to access any third-party links or features, you do so at your own risk. We are not liable for damages or losses that you incur as a result of accessing third-party links or features.</p>
	</div>

	<div class="section">
		<h3 class="section-title">11. Your Account</h3>

		<p>In order to access certain features and functionality of the Services, you must establish an account with us (an "Account“). You are not permitted to share, sell, distribute or otherwise transfer your Account or allow your login credentials to be used by any other individual. You shall maintain the security and confidentiality of your login credentials used to access your Account. You shall choose a strong and secure password that you shall keep secure and confidential. You shall promptly notify Divine Limousine in the event you believe your login credentials have been compromised or there has been unauthorized use of your Account. You are solely responsible for any activities or actions taken under your Account, including as a result of the unauthorized use of your Account. Divine Limousine is not liable for any loss you incur as a result of your failure to comply with this section.</p>
	</div>

	<div class="section">
		<h3 class="section-title">12. Suspension and Termination</h3>

		<p>Divine Limousine may, in its discretion, terminate or suspend your access to all or part of the Services, temporarily or permanently, for any reason, with or without notice to you. Upon termination of these Terms for any reason, your license to access or use the Services in any way will terminate, you shall immediately cease use, and you shall immediately securely destroy any and all Divine Limousine Content and Divine Limousine Data, whether in electronic form or hard copy. Upon request, and within 5 days of that request, you shall certify such destruction to Divine Limousine’s reasonable satisfaction using the form provided by Divine Limousine. Divine Limousine may audit your compliance with these obligations and Divine Limousine’s intellectual property license upon 5 days’ prior written notice.</p>
	</div>

	<div class="section">
		<h3 class="section-title">13. Warranty Disclaimer</h3>

		<p>YOUR USE OF THE SERVICES ARE AT YOUR OWN RISK. THE SERVICES, INCLUDING ALL SERVICES AND CONTENT, ARE PROVIDED “AS IS” AND “AS AVAILABLE.” WE MAKE NO WARRANTIES OR REPRESENTATIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED, ABOUT THE SERVICES, INCLUDING ALL DIVINE LIMOUSINE DATA, SERVICES, CONTENT, INFORMATION, SOFTWARE, FUNCTIONS, PROCESSES, OR OTHER CONTENT PROVIDED OR REFERENCED HEREIN.</p>
		<p>WE HEREBY DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. WE DISCLAIM ALL WARRANTIES RELATING TO THE AVAILABILITY, ACCURACY, SECURITY, PRIVACY, CONFIDENTIALITY, APPROPRIATENESS, RELIABILITY, COMPLETENESS, OR TIMELINESS RELATING TO THE SERVICES. WE DISCLAIM ANY WARRANTY THAT THE SERVICES OR SERVICES WILL BE UNINTERRUPTED, NOT DELAYED OR SUSPENDED, UNCHANGED, OR ERROR OR VIRUS FREE, OR THAT WE WILL CORRECT ANY DEFECTS. WE MAKE NO REPRESENTATIONS THAT THE MATERIALS CONTAINED WITHIN THE SERVICES ARE APPROPRIATE FOR LOCATIONS OUTSIDE THE UNITED STATES.</p>
		<p>The Terms do not affect those statutory rights that you are entitled to as a consumer and that you cannot contractually agree to alter or waive.</p>
	</div>

	<div class="section">
		<h3 class="section-title">14. Representations and Warranties</h3>

		<p>You represent, warrant and covenant that:</p>
		<ol>
			<li>You have the right and authority to enter into these Terms;</li>
			<li>if you are using the Service on behalf of yourself, your employer, or any other company, that you have the authority to enter into these Terms on behalf of such employer or company;</li>
			<li>you have all rights and permissions necessary (including, but not limited to, intellectual property right) to lawfully make available the Submitted Data to Divine Limousine for the purposes contemplated by these Terms;</li>
			<li>you have the right and authority to provide us with access to any and all of your devices, browsers, email accounts, social media accounts, professional networking or customer relationship management platforms and other online accounts from which you use the Services;</li>
			<li>all Submitted Data has been and will be obtained by you, and shared with or transferred to Divine Limousine, in compliance with all applicable laws, rules and regulations, including without limitation, ensuring that any required notices have been provided to and necessary consents obtained from individuals or entities; and</li>
			<li>you have and will continue to take reasonable steps to ensure that Submitted Data is accurate, complete and up-to-date.</li>
		</ol>
	</div>

	<div class="section">
		<h3 class="section-title">15. Indemnification</h3>

		<p>You agree to defend, indemnify and hold Divine Limousine, its affiliates and their respective officers, directors, employees, agents, contractors and business partners harmless from and against any claims, actions, demands, losses, liabilities, fines, costs, including, without limitation, legal and accounting fees, arising or resulting from:</p>
		<ol>
			<li>Your breach of these Terms;</li>
			<li>Your access to, use or misuse of the Services or any Divine Limousine Data, Submitted Data or features provided or made available through the Services;</li>
			<li>Your negligence, actions or omissions;</li>
			<li>Your violation or alleged violation of any law, regulation or ordinance; or</li>
			<li>Your violation or alleged violation of the rights of any third party.</li>
		</ol>
		<p>We reserve the right to assume the exclusive defense and control of any matter which is subject to indemnification under these Terms at your expense. In such case, you agree to cooperate with any reasonable requests assisting us in the defense of such matter. Your obligations as stated herein survive these Terms and your use of the Services.</p>
	</div>

	<div class="section">
		<h3 class="section-title">16. Limitation of Liability</h3>

		<p>EXCEPT TO THE EXTENT PROHIBITED BY APPLICABLE LAW, WE ARE NOT LIABLE FOR ANY INDIRECT, INCIDENTAL, EXEMPLARY, PUNITIVE DAMAGES, OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, LOST PROFITS, LOST SAVINGS, LOSS OF GOODWILL, BUSINESS REPUTATION, BUSINESS OPPORTUNITIES OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION) RESULTING FROM THE USE OR INABILITY TO USE THE SERVICES, OR ANY DIVINE LIMOUSINE DATA, INFORMATION, OR SERVICES PROVIDED FOR ON OR BY THE SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, EVEN IF YOU HAVE NOTIFIED US ABOUT SUCH DAMAGES, OR FOR ANY CLAIMS BY ANY THIRD-PARTIES. IN NO EVENT WILL DIVINE LIMOUSINE’S AGGREGATE LIABILITY TO YOU, DIRECT OR OTHERWISE, EXCEED ONE HUNDRED DOLLARS ($100).</p>
		<p>DIVINE LIMOUSINE IS NOT LIABLE FOR THE ACCURACY, COMPLETENESS, AVAILABILITY OF THE SERVICES, DIVINE LIMOUSINE DATA OR SERVICES. THE CONTENT OR MATERIALS APPEARING ON OUR SITE MAY INCLUDE TECHNICAL, TYPOGRAPHICAL, OR PHOTOGRAPHIC ERRORS. DIVINE LIMOUSINE IS NOT RESPONSIBLE FOR THESE ERRORS.</p>
		<p>Some states may not allow certain limitations and exclusions of liability, so the above limitations or exclusions may not apply to you. In such states, our liability to you shall be limited to the greatest extent permitted by law.</p>
	</div>

	<div class="section">
		<h3 class="section-title">17. Arbitration Agreement and Class Action Waiver</h3>

		<p><strong>A. Mandatory Arbitration.</strong> YOU AND DIVINE LIMOUSINE AGREE TO SUBMIT ANY AND ALL DISPUTES ARISING OUT OF OR RELATED TO YOUR USE OF THE SERVICES (each, a “Dispute”) to binding arbitration pursuant to the Federal Arbitration Act (Title 9 of the United States Code), which shall govern the interpretation and enforcement of this arbitration agreement (“Arbitration Agreement”). You and Divine Limousine waive the right to a trial by jury and any right to have a Dispute heard in court. Notwithstanding the foregoing, both you and Divine Limousine retain the right to bring an individual action in small claims court (if your claims qualify and so long as the matter remains in such court and advances only on an individual (non-class, non-representative) basis) and the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation, or violation of either party’s copyrights, trademarks, trade secrets, patents, or other intellectual property rights.</p>
		<p>In arbitration, Disputes are resolved by a neutral arbitrator instead of a judge or jury, discovery is more limited than in court, and the arbitrator's decision is subject to limited review by courts. The arbitrator can award on an individual basis the same damages and relief as a court, including monetary damages, injunctive relief, and declaratory relief. Judgment on the arbitrator's award may be entered in any court having jurisdiction thereof. A single arbitrator with the American Arbitration Association ("AAA“) will conduct the arbitration, and the award may not exceed the relief allowed by applicable law. The arbitration will be conducted in the county of your residence or another mutually agreed location, provided that if the value of the relief sought is $10,000 or less, you or Divine Limousine may elect to have the arbitration conducted by telephone or based solely on written submissions, which election will be binding on you and Divine Limousine, subject to the arbitrator’s discretion to require an in-person hearing, if the circumstances warrant. In cases where an in-person hearing is held, you or Divine Limousine may attend by telephone, unless the arbitrator requires otherwise. The AAA's Consumer Arbitration Rules will apply. If AAA will not apply those rules, then AAA's Commercial Arbitration Rules will apply. The AAA's rules and a form that can be used to initiate arbitration proceedings are available at https://www.adr.org/. You and Divine Limousine agree that if for any reason AAA will not conduct or becomes unavailable to conduct the arbitration, then a court may appoint a substitute arbitrator, and further agree that the choice of AAA as a forum is not integral to the Arbitration Agreement.</p>
		<p>The arbitrator will decide all issues relating to the enforceability, interpretation, scope, and application of this Arbitration Agreement (including “gateway” issues of arbitrability, whether the Arbitration Agreement is unconscionable or illusory and any defense to arbitration), and these Terms, except that a court will resolve any question regarding the validity or enforceability of the class action waiver set forth in Section B of this Arbitration Agreement. The term "Dispute“ and the requirement to arbitrate will be broadly interpreted.</p>
		<p><strong>B. Arbitration Class Action Waiver.</strong> You and Divine Limousine agree that the arbitration will be conducted solely on an individual basis and not on a class, representative, consolidated, or private attorney general basis. A Dispute may not be consolidated with a claim brought or discovery by any person or entity that is not a party to the arbitration proceeding. The arbitrator may not award relief to any person or entity other than a party to the arbitration proceeding and may only award such relief as is necessary to provide relief to a party to the arbitration proceeding. If a court deems any portion of this Section B invalid or unenforceable, then Sections A and C of this Arbitration Agreement will be null and void.
		<p><strong>C. Fees and Costs in Arbitration.</strong> Payment of all filing, administration and arbitrator fees will be governed by the AAA's rules.
		<p><strong>D. Non-Arbitration Class Action and Jury Waiver.</strong> You and Divine Limousine agree that if for any reason a Dispute proceeds in court rather than arbitration: (1) you and Divine Limousine waive any right to a jury trial; (2) the Dispute will proceed solely on an individual, non-class, non-representative basis; and (3) neither you nor Divine Limousine may be a class representative or class member or otherwise participate in any class, representative, consolidated, or private attorney general proceeding.
		<p><strong>E. NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS SECTION, YOU MAY CHOOSE TO PURSUE YOUR CLAIM IN COURT AND NOT BY ARBITRATION IF YOU OPT OUT OF THIS ARBITRATION AGREEMENT WITHIN 30 DAYS FROM THE EARLIER OF: (1) THE DATE YOU ACCEPT THESE TERMS, OR (2) THE DATE YOU FIRST AGREED OR ASSENTED TO AN AGREEMENT WITH DIVINE LIMOUSINE THAT CONTAINED AN ARBITRATION PROVISION. The opt-out notice must be postmarked no later than the applicable deadline and e-mailed to:  <a href="mailto:divine.info@divinelimousine.com">divine.info@divinelimoutah.com</a>. The opt-out notice must state that you do not agree to this Arbitration Agreement and must include your name, address, phone number and email address. This procedure is the only way you can opt out of this Arbitration Agreement, and failure to comply strictly with this procedure and the applicable deadline automatically will render the opt-out notice null and void. If you opt out of the arbitration provision, all other parts of this Arbitration Agreement will continue to apply.</strong></p>
	</div>

	<div class="section">
		<h3 class="section-title">18. Notice</h3>

		<p>All notices in connection with these Terms will be given by regular mail (postage prepaid, return receipt requested), courier or overnight delivery service or email.</p>
		<p>
			You may provide notice to Divine Limousine at:<br>
			Divine Limousine<br>
			P.O. Box 651676<br>
			Salt Lake City, UT 84165
		</p>

		<p>You may ask questions or contact us by phone at (801) 327-8752 or by email at <a href="mailto:divine.info@divinelimousine.com">divine.info@divinelimoutah.com</a>. </p>
		<p>Divine Limousine may provide notice to you using the contact information associated with your Account. </p>
	</div>

	<div class="section">
		<h3 class="section-title">19. Other Terms</h3>

		<div class="section">
			<h4>A. Injunctive Relief</h4>
			<p>You agree and acknowledge that any access, use, disclosure, retention or other processing of Divine Limousine Data in violation of these Terms may cause immediate and irreparable harm to Divine Limousine for which money damages may not constitute an adequate remedy. Therefore, you agree that Divine Limousine may obtain specific performance and injunctive or other equitable relief, in addition to its remedies at law. Divine Limousine shall be entitled to such equitable relief in addition to all other remedies at law or in equity.</p>
		</div>

		<div class="section">
			<h4>B. Choice of Law and Venue</h4>
			<p>These Terms are governed by the internal substantive laws of the State of Utah without respect to its conflict of laws provisions. You expressly agree to submit to the exclusive personal jurisdiction of the state and federal courts in Salt Lake County, Utah, excluding any Disputes subject to the dispute resolution procedures in Section 18 (Arbitration Agreement and Class Action Waiver).</p>
		</div>

		<div class="section">
			<h4>C. Severability</h4>
			<p>If any provision of these Terms is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms, which shall remain in full force and effect.</p>
		</div>
		
		<div class="section">
			<h4>D. No Waiver</h4>
			<p>Failure by us to act on or enforce any provision of these Terms shall not be construed as a waiver of that provision or any other provision in these Terms. No waiver shall be effective against us unless made in writing, and no such waiver shall be construed as a waiver in any other or subsequent instance.</p>
		</div>
		
		<div class="section">
			<h4>E. Entire Agreement</h4>
			<p>Except as expressly agreed to in writing by us, these Terms constitute the entire agreement between the parties with respect to the subject matter, and supersede all previous or contemporaneous agreements, whether written or oral, between the parties with respect to the subject matter.</p>
		</div>
		
		<div class="section">
			<h4>F. Headings</h4>
			<p>The section headings are provided merely for convenience and must not be given any legal import. References to Divine Limousine includes references to its affiliates, subsidiaries, officers, directors, employees, agents, suppliers, and licensors.</p>
		</div>
		
		<div class="section">
			<h4>G. Assignment</h4>
			<p>These Terms will inure to the benefit of our successors, assigns, licensees, and sublicensees. We may assign our rights or obligations without your consent. You may not assign or transfer any rights or obligations under these Terms (or your account) to anyone without our consent and any assignment or transfer in violation of this section is void.</p>
		</div>
		
		<div class="section">
			<h4>H. No Third-Party Beneficiary</h4>
			<p>These Terms do not create any third-party beneficiary rights to any person who is not a party.</p>
		</div>
		
		<div class="section">
			<h4>I. Survival</h4>
			<p>Provisions of the Terms relating to limitation of liability, indemnification, venue, choice of law, and any other provisions that by their nature should survive, will survive the termination of these Terms.</p>
		</div>
		
		<div class="section">
			<h4>J. Logos</h4>
			<p>Unless you notify us otherwise in writing, you hereby authorize us to use your company’s logo, name, or trademark on our website or in marketing.</p>
		</div>
	</div>
</div>