<div class="app">
	<div class="container">
		<form [formGroup]="registerForm" (ngSubmit)="registerSalesman(registerForm.value)" novalidate>
			<div class="field">
				<input type="text" formControlName="first_name" placeholder="First Name *">
				<p class="form-error" *ngIf="registerForm.get('first_name').touched && registerForm.get('first_name').hasError('required')">
					This field is required
				</p>
			</div>

			<div class="field">
				<input type="text" formControlName="last_name" placeholder="Last Name *">
				<p class="form-error" *ngIf="registerForm.get('last_name').touched && registerForm.get('last_name').hasError('required')">
					This field is required
				</p>
			</div>

			<div class="field">
				<input type="email" formControlName="email" value="" placeholder="Email *">
				<p class="form-error" *ngIf="registerForm.get('email').touched && registerForm.get('email').hasError('required')">
					This field is required
				</p>
				<p class="form-error" *ngIf="registerForm.get('email').touched && registerForm.get('email').hasError('email')">
					Invalid Email
				</p>
			</div>

			<div class="field">
				<input id="tel" type="tel" formControlName="primary_phone" placeholder="Primary Phone *"
					phoneMask
					[preValue]="registerForm.value.primary_phone"
					[phoneControl]="registerForm.controls['primary_phone']" maxlength="17"
					autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false">
				<p class="form-error" *ngIf="registerForm.get('primary_phone').touched && registerForm.get('primary_phone').hasError('required')">
					This field is required
				</p>
				<p class="form-error" *ngIf="registerForm.get('primary_phone').touched && registerForm.get('primary_phone').hasError('phone')">
					Invalid phone
				</p>
			</div>

			<div class="field">
				<input id="tel" type="tel" formControlName="secondary_phone" placeholder="Secondary Phone"
					phoneMask
					[preValue]="registerForm.value.secondary_phone"
					[phoneControl]="registerForm.controls['secondary_phone']" maxlength="17"
					autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false">
				<p class="form-error" *ngIf="registerForm.get('secondary_phone').touched && registerForm.get('secondary_phone').hasError('required')">
					Invalid phone number
				</p>
			</div>

			<div class="field">
				<input type="password" formControlName="password" placeholder="Password *">
				<p class="form-error" *ngIf="registerForm.get('password').touched && registerForm.get('password').hasError('required')">
					This field is required
				</p>
				<p class="form-error" *ngIf="registerForm.get('password').touched && registerForm.get('password').errors">Your password must have at least 8 characters, 1 upper and lower case letter, 1 number and 1 special character</p>
			</div>

			<div class="field">
				<input type="password" formControlName="confirm_password" placeholder="Confirm Password *">
				<p class="form-error" *ngIf="registerForm.get('confirm_password').touched && registerForm.get('confirm_password').hasError('noMatch')">
					Your passwords don't match
				</p>
			</div>
			
			<div class="field">
				<button class="btn btn-primary" type="submit">Register</button>
				<img *ngIf="loading" class="processing" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
			</div>

			<div class="field" *ngIf="serverError">
				<p class="form-error">{{ serverError }}</p>
			</div>
		</form>

		<a routerLink="/">Back to login</a>
	</div>
</div>