import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms'
import { Router } from '@angular/router'

// Validators
import { CommonValidators } from '../../_validators/common.validator'

// Services
import { AuthenticationService } from '../../_services'

@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
	public registerForm: FormGroup
	public loading: boolean = false
	public serverError

	constructor(private _fb:FormBuilder, private auth: AuthenticationService, private router: Router) { }

	get f() { return this.registerForm.controls }

	ngOnInit() {
		this.createRegisterForm()
	}

	createRegisterForm() {
		this.registerForm = this._fb.group({
			'first_name': ['', Validators.required],
			'last_name': ['', Validators.required],
			'email': ['', [Validators.required, Validators.email]],
			'primary_phone': ['', [Validators.required, CommonValidators.phone]],
			'secondary_phone': ['', CommonValidators.phone],
			'password': ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{8,}')]],
			'confirm_password': ['', [Validators.required, CommonValidators.passwordMatches('password')]]
		})

		let controls = this.registerForm.controls

		controls.password.valueChanges.subscribe(() => {
			controls.confirm_password.updateValueAndValidity()
		})
	}

	formatPhone() {
		this.f.primary_phone.patchValue(this.f.primary_phone.value.replace(/\D/g, ''))
		this.f.secondary_phone.patchValue(this.f.secondary_phone.value.replace(/\D/g, ''))
	}

	registerSalesman(data) {
		this.loading = true
		this.serverError = null
		data.primary_phone = data.primary_phone.replace(/\D/g,'')
		data.secondary_phone = data.secondary_phone.replace(/\D/g,'')



		this.auth.register(data).subscribe( res => {
			this.auth.setUser(res)
			this.router.navigate(['/user'])
		}, (err) => {
			this.serverError = err
			this.loading = false
		})
	}

}
