import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms'
import { HttpErrorResponse } from '@angular/common/http'
import { CommonValidators } from '../../../_validators/common.validator'

//Services
import { AuthenticationService, UserService } from '../../../_services'

import { Referral } from '../../../_models'

@Component({
	selector: 'refer',
	templateUrl: './refer.component.html'
})
export class ReferComponent implements OnInit {
	referalForm: FormGroup
	formErrors: Array<string>
	loading: boolean = false
	submitted: boolean = false
	success: boolean = false

	constructor(
		private _fb:FormBuilder, 
		private auth: AuthenticationService,
		private userService: UserService
	) { }

	get f() { return this.referalForm.controls }

	ngOnInit() {
		this.createReferalForm()
	}

	createReferalForm() {
		this.referalForm = this._fb.group({
			'name': [''],
			'primary_phone': ['', [Validators.required, CommonValidators.phone]],
			'contact_preference': ['', Validators.required]
		})
	}

	formatPhone() {
		if(!this.f.primary_phone.value.length) {
			return 
		}

		

		this.f.primary_phone.patchValue(this.f.primary_phone.value.replace(/\D/g, ''))
	}

	// checkPhone() {
	// 	if(this.f.primary_phone.value.length == 10) {
	// 		this.f.primary_phone.patchValue('1' + this.f.primary_phone.value)
	// 	}
	// }

	performReferal(client: Referral) {
		this.success = false
		this.submitted = true

		if(!this.referalForm.valid) {
			return
		}

		
		this.formErrors = []
		this.loading = true

		client.primary_phone = client.primary_phone.replace(/\D/g, '')
	
		this.userService.referClient(client).subscribe( res => {

			this.loading = false
			this.success = true
			console.log(this.loading, this.success, this.submitted)
			this.referalForm.reset()
		}, (error) => {
			console.log(error)
			this.formErrors.push(error)
			this.loading = false
			this.success = false
			this.submitted = false
		})
	}

}
