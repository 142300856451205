import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';



//Components
import { AppComponent } from './app.component'

const routes: Routes = [
	// Redirect to login if no route matches
	// { path: '**', redirectTo: '' }
]

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
	],
	exports: [
		RouterModule
	]
})

export class AppRoutingModule{}