<div class="app">
	<user-header></user-header>

	<div class="container">
		<h3 class="page-title">Refer a potential client</h3>

		<form [formGroup]="referalForm" (ngSubmit)="performReferal(referalForm.value)" novalidate>
			<div class="field">
				<input type="text" formControlName="name" value="" placeholder="Name">
			</div>

			<div class="field">
				<input id="tel" type="tel" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
					formControlName="primary_phone" 
					value="" 
					placeholder="Primary Phone *" 
					phoneMask 
					[preValue]="referalForm.value.primary_phone"
					[phoneControl]="referalForm.controls['primary_phone']" maxlength="17">
				<p class="form-error" *ngIf="referalForm.get('primary_phone').hasError('phone') && referalForm.get('primary_phone').touched">Invalid phone number</p>
			</div>

			<div class="field">

				<div class="radio-group">
					<p>Preference *</p>
					
					<label>
						<input type="radio" formControlName="contact_preference" value="call">
						<span>Call</span>
					</label>

					<label>
						<input type="radio" formControlName="contact_preference" value="text">
						<span>Text</span>
					</label>
				</div>
			</div>

			<div class="field submit">
				<button class="btn btn-primary">Send Referal</button>
				<img *ngIf="loading" class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
			</div>

			<div *ngIf="formErrors"> 
				<p class="form-error" *ngFor="let error of formErrors">{{ error }}</p>
			</div>

			<p *ngIf="submitted && !loading && success" class="form-success">Refferal successfully sent</p>
		</form>
	</div>
</div>